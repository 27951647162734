import { useEffect } from 'react';
import { executePostRedirectAction } from 'frontend-container/components/AlternateDomainSwitching/fasterDomainRedirect/executePostRedirectAction';
import { isAppInitializationAfterRedirectFromSlowerDomain } from 'frontend-container/components/AlternateDomainSwitching/fasterDomainRedirect/isAppInitializationAfterRedirectFromSlowerDomain';
import { isAlternateDomainSwitchingEnabled } from 'frontend-container/components/AlternateDomainSwitching/featureAvailability/isFeatureEnabled';
import { initializeAlternateDomainsVerification } from 'frontend-container/components/AlternateDomainSwitching/verification/initializeAlternateDomainsVerification';

export const AlternateDomainSwitching = (): JSX.Element | null => {
  useEffect(() => {
    if (!isAlternateDomainSwitchingEnabled()) {
      return;
    }

    if (isAppInitializationAfterRedirectFromSlowerDomain()) {
      executePostRedirectAction();
    } else {
      initializeAlternateDomainsVerification();
    }
  }, []);

  return null;
};
