import { SessionDataQueryParam } from 'frontend-container/components/SessionData/sessionDataQueryParam';

import { SessionService } from '@ac/library-utils/dist/services';

type QueryParamHandlerMap = {
  [key: string]: (value: string) => void;
};

const queryParamHandlerMap: QueryParamHandlerMap = {
  [SessionDataQueryParam.PropertyId]: (id) => SessionService.setPropertyId(id),
  [SessionDataQueryParam.CroId]: (id) =>
    SessionService.setCentralReservationOfficeId(id),
  [SessionDataQueryParam.ProfileCenterId]: (id) =>
    SessionService.setProfileCenterId(id),
};

export const applySessionDataFromUrl = (): void => {
  const [baseUrl, queryString] = window.location.href.split('?');
  const searchParams = new URLSearchParams(queryString);

  Object.entries(queryParamHandlerMap).forEach(([param, handler]) => {
    const value = searchParams.get(param);
    if (value !== null) {
      handler(value);
      searchParams.delete(param);
    }
  });

  const newQueryString = searchParams.toString();
  const newUrl = `${baseUrl}${newQueryString ? `?${newQueryString}` : ''}`;

  window.history.replaceState({}, document.title, newUrl);
};
