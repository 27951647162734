import { DomainsSpeedTestResult } from 'frontend-container/components/AlternateDomainSwitching/verification/speedTest/domainsSpeedTestResult';
import { KeysForOverriddenLogLevel } from 'frontend-container/utils/logger/configurationLogKeys';
import { logger } from 'frontend-container/utils/logger/logger';

export const logDebugInfo = (message: string, data?: unknown): void => {
  logger?.debug(
    {
      message,
      data,
    },
    {
      keyForOverriddenLogLevel:
        KeysForOverriddenLogLevel.AlternateDomainSwitching,
    }
  );
};

export const logSpeedVerificationResult = (
  speedTestResult: DomainsSpeedTestResult
): void => {
  logger?.information(
    {
      actionType: 'SpeedVerificationResult',
      speedTestResult,
    },
    {
      keyForOverriddenLogLevel:
        KeysForOverriddenLogLevel.AlternateDomainSwitching,
    }
  );

  if (speedTestResult.failedDomainTestResults.length) {
    logger?.error(
      {
        failedDomainTestResults: speedTestResult.failedDomainTestResults,
      },
      {
        keyForOverriddenLogLevel:
          KeysForOverriddenLogLevel.AlternateDomainSwitching,
      }
    );
  }
};

export const logRedirectToFasterDomain = (targetUrl: string): void => {
  logger?.information(
    {
      actionType: 'RedirectToFasterDomain',
      message: targetUrl,
    },
    {
      keyForOverriddenLogLevel:
        KeysForOverriddenLogLevel.AlternateDomainSwitching,
    }
  );
};

export const logRedirectToPreviousDomain = (): void => {
  logger?.information(
    {
      actionType: 'RedirectToPreviousDomain',
    },
    {
      keyForOverriddenLogLevel:
        KeysForOverriddenLogLevel.AlternateDomainSwitching,
    }
  );
};

export const logError = (error: unknown): void => {
  logger?.error(
    {
      error,
    },
    {
      keyForOverriddenLogLevel:
        KeysForOverriddenLogLevel.AlternateDomainSwitching,
    }
  );
};
