interface Params {
  currentLocation: Location;
  targetLocation: Location;
}

export const isSameHostAndPath = ({
  currentLocation,
  targetLocation,
}: Params): boolean =>
  currentLocation.host === targetLocation.host &&
  currentLocation.pathname === targetLocation.pathname;
