import { DomainSpeed } from 'frontend-container/components/AlternateDomainSwitching/verification/speedTest/domainsSpeedTestResult';

interface Props {
  speedThresholdInMbps: number;
  allDomainSpeeds: DomainSpeed[];
}

export const findFasterDomainsThanThreshold = ({
  speedThresholdInMbps,
  allDomainSpeeds,
}: Props): DomainSpeed | undefined => {
  const fasterDomains = allDomainSpeeds.filter(
    ({ speedInMbps }) => speedInMbps >= speedThresholdInMbps
  );

  if (!fasterDomains) {
    return;
  }

  let domainWithMaxSpeed = fasterDomains[0];

  for (const domainSpeedResult of fasterDomains) {
    if (domainSpeedResult.speedInMbps > domainWithMaxSpeed.speedInMbps) {
      domainWithMaxSpeed = domainSpeedResult;
    }
  }

  return domainWithMaxSpeed;
};
