import { addRedirectListenerForFasterDomainSwitch } from 'frontend-container/components/AlternateDomainSwitching/fasterDomainRedirect/addRedirectListenerForFasterDomainSwitch';
import { logDebugInfo } from 'frontend-container/components/AlternateDomainSwitching/logging/logging';
import { getSpeedTestConfigFromLocalStorage } from 'frontend-container/components/AlternateDomainSwitching/storage/localStorageData';
import { getDefaultAlternateDomainsSpeedTestConfig } from 'frontend-container/components/AlternateDomainSwitching/verification/configuration/testConfig';
import { proceedAlternateDomainsSpeedTest } from 'frontend-container/components/AlternateDomainSwitching/verification/proceedAlternateDomainsSpeedTest';
import { isSpeedTestResultOutdated } from 'frontend-container/components/AlternateDomainSwitching/verification/testRepetition/isSpeedTestResultOutdated';
import { saveSpeedTestMarkerInStore } from 'frontend-container/components/AlternateDomainSwitching/verification/testRepetition/saveSpeedTestMarkerInStore';

export const initializeAlternateDomainsVerification = (): Promise<void> => {
  if (!isSpeedTestResultOutdated()) {
    return Promise.resolve();
  }

  const customConfig = getSpeedTestConfigFromLocalStorage();
  const config = customConfig ?? getDefaultAlternateDomainsSpeedTestConfig();

  const executeProcess = async (): Promise<void> => {
    const { fasterDomainThanCurrent } = await proceedAlternateDomainsSpeedTest({
      config,
    });

    saveSpeedTestMarkerInStore();

    if (fasterDomainThanCurrent) {
      logDebugInfo(
        'Faster domain has been determined. Waiting for redirect...',
        fasterDomainThanCurrent
      );

      addRedirectListenerForFasterDomainSwitch(fasterDomainThanCurrent);
    } else {
      logDebugInfo('Faster domain has not been found');
    }
  };

  return new Promise<void>((resolve) => {
    setTimeout(async () => {
      await executeProcess();
      resolve();
    }, config.testDelayInMilliseconds);
  });
};
