import { CurrentRegionDomains } from 'frontend-container/components/AlternateDomainSwitching/types/currentRegionDomains';
import { AlternateDomainsSpeedTestConfig } from 'frontend-container/components/AlternateDomainSwitching/verification/configuration/testConfig';
import { DomainSpeedTest } from 'frontend-container/components/AlternateDomainSwitching/verification/speedTest/domainSpeedTest';
import {
  DomainSpeed,
  DomainsSpeedTestResult,
  FailedDomainSpeedResult,
} from 'frontend-container/components/AlternateDomainSwitching/verification/speedTest/domainsSpeedTestResult';
import { findFasterDomainsThanThreshold } from 'frontend-container/components/AlternateDomainSwitching/verification/speedTest/findFasterDomains';

interface Params {
  domains: CurrentRegionDomains;
  config: AlternateDomainsSpeedTestConfig;
}

export const verifyDomainsDownloadSpeed = async ({
  domains,
  config,
}: Params): Promise<DomainsSpeedTestResult> => {
  if (!domains.alternateDomains.length) {
    throw new Error(
      'Cannot run download speed test verification when alternate domains are not defined for region'
    );
  }

  const speedTestConfig = {
    bytesToReturn: config.responseSizeToDownloadInBytes,
  };

  const currentDomainSpeedTestResult = await new DomainSpeedTest(
    domains.currentDomain,
    speedTestConfig
  ).runDownloadSpeedTest();

  if (currentDomainSpeedTestResult.type === 'failed') {
    throw new Error(
      `Cannot define current domain speed: ${currentDomainSpeedTestResult.error}`
    );
  }

  const currentDomainSpeedInMbps = currentDomainSpeedTestResult.speedInMbps;
  const alternateDomainsSpeeds: DomainSpeed[] = [];
  const failedDomainTestResults: FailedDomainSpeedResult[] = [];

  const domainSpeedTests = domains.alternateDomains.map(
    (domain) => new DomainSpeedTest(domain, speedTestConfig)
  );

  // Note: Avoid Promise.all() pattern and verify the network quality linearly (domain by domain)
  for (const domainSpeedTest of domainSpeedTests) {
    const result = await domainSpeedTest.runDownloadSpeedTest();

    if (result.type === 'success') {
      alternateDomainsSpeeds.push({
        domain: domainSpeedTest.domain,
        speedInMbps: result.speedInMbps,
      });
    } else {
      failedDomainTestResults.push({
        domain: domainSpeedTest.domain,
        error: result.error,
      });
    }
  }

  const fasterDomainThanCurrent = findFasterDomainsThanThreshold({
    allDomainSpeeds: alternateDomainsSpeeds,
    speedThresholdInMbps:
      config.fasterSpeedValueRatio * currentDomainSpeedInMbps,
  });

  return {
    currentDomainSpeedInMbps,
    alternateDomainsSpeeds,
    failedDomainTestResults,
    fasterDomainThanCurrent,
  };
};
