import { SessionDataQueryParam } from 'frontend-container/components/SessionData/sessionDataQueryParam';

import { SessionService } from '@ac/library-utils/dist/services';

export const extendUrlSearchParamsBySessionData = (
  searchParams: URLSearchParams
): void => {
  const sessionData = {
    propertyId: SessionService.getPropertyId(),
    croId: SessionService.getCentralReservationOfficeId(),
    profileCenterId: SessionService.getProfileCenterId(),
  };

  if (sessionData.propertyId) {
    searchParams.set(SessionDataQueryParam.PropertyId, sessionData.propertyId);
  }

  if (sessionData.croId) {
    searchParams.set(SessionDataQueryParam.CroId, sessionData.croId);
  }

  if (sessionData.profileCenterId) {
    searchParams.set(
      SessionDataQueryParam.ProfileCenterId,
      sessionData.profileCenterId
    );
  }
};
