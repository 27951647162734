import { isSameHostAndPath } from 'frontend-container/components/AlternateDomainSwitching/fasterDomainRedirect/isSameHostAndPath';
import { buildTargetDomainUrl } from 'frontend-container/components/AlternateDomainSwitching/fasterDomainRedirect/url';
import { logRedirectToFasterDomain } from 'frontend-container/components/AlternateDomainSwitching/logging/logging';
import { DomainInfo } from 'frontend-container/components/AlternateDomainSwitching/types/domainInfo';

export const addRedirectListenerForFasterDomainSwitch = (
  targetDomain: DomainInfo
): void => {
  const currentLocation = { ...window.location };

  if (currentLocation.href.includes(targetDomain.web)) {
    return;
  }

  const redirectCallback = (): void => {
    const targetLocation = window.location;

    if (isSameHostAndPath({ currentLocation, targetLocation })) {
      return;
    }

    window.removeEventListener('popstate', redirectCallback);

    const targetUrl = buildTargetDomainUrl({
      targetLocation,
      targetDomain: targetDomain.web,
    });

    logRedirectToFasterDomain(targetUrl);

    window.location.href = targetUrl;
  };

  window.addEventListener('popstate', redirectCallback);
};
