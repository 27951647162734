import { extendUrlSearchParamsBySessionData } from 'frontend-container/components/SessionData/extendUrlSearchParamsBySessionData';

const REDIRECT_FROM_SLOWER_DOMAIN_QUERY_PARAMETER = 'redirectFromSlowerDomain';

interface Params {
  targetLocation: {
    pathname: string;
    search: string;
  };
  targetDomain: string;
}

export const buildTargetDomainUrl = ({
  targetLocation,
  targetDomain,
}: Params): string => {
  const currentPath = targetLocation.pathname;
  const currentQueryParams = targetLocation.search;
  const redirectQueryParams = new URLSearchParams();
  redirectQueryParams.set(REDIRECT_FROM_SLOWER_DOMAIN_QUERY_PARAMETER, 'true');

  extendUrlSearchParamsBySessionData(redirectQueryParams);

  const newQueryParams = currentQueryParams
    ? `${currentQueryParams}&${redirectQueryParams.toString()}`
    : `?${redirectQueryParams.toString()}`;

  return `${targetDomain}${currentPath}${newQueryParams}`;
};

export const isDomainSwitchingQueryStringInUrl = (): boolean =>
  new URLSearchParams(window.location.search).has(
    REDIRECT_FROM_SLOWER_DOMAIN_QUERY_PARAMETER
  );
