import { croLandingPagePathname } from 'frontend-container/components/LandingPage';
import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context/context';
import { SessionDataQueryParam } from 'frontend-container/components/SessionData/sessionDataQueryParam';

import { getOriginForNewRegion as getGenericOriginForNewRegion } from '@ac/library-utils/dist/utils/multi-region';

export const contextTypeRegionChangeQueryParamMapper = (
  context: Context
): SessionDataQueryParam => {
  switch (context.type) {
    case ContextType.CRO:
      return SessionDataQueryParam.CroId;
    default:
      return SessionDataQueryParam.PropertyId;
  }
};

const getRelativeUrlWithContextParam = (
  customPathname?: string,
  context?: Context
): string => {
  const basePath = customPathname || window.location.pathname;

  return getUrlWithContextParam(
    `${basePath}${window.location.search}`,
    context
  );
};

const getRelativeUrlWithContextParamWithoutLocationSearch = (
  customPathname?: string,
  context?: Context
): string => {
  const basePath = customPathname || window.location.pathname;

  return getUrlWithContextParam(basePath, context);
};

export const getUrlWithContextParam = (
  url: string,
  context?: Context
): string => {
  const [fullPath, queryString] = url.split('?');
  const searchParams = new URLSearchParams(queryString);

  if (context && context.id) {
    searchParams.set(
      contextTypeRegionChangeQueryParamMapper(context),
      context.id
    );
  }

  const newQueryString = searchParams.toString();
  const newUrl = `${fullPath}${newQueryString ? `?${newQueryString}` : ''}`;

  return newUrl;
};

export const getOriginForNewRegion = (
  regionCode: string,
  domain: string
): string =>
  getGenericOriginForNewRegion({
    regionCode,
    domain,
    applicationNameInUrl: 'web',
  });

export const getUrlForNewRegion = (
  regionCode: string,
  domain: string,
  context?: Context,
  customPathname?: string
): string =>
  `${getOriginForNewRegion(regionCode, domain)}${getRelativeUrlWithContextParam(
    customPathname,
    context
  )}`;

export const getCroUrlForNewRegion = (
  regionCode: string,
  domain: string,
  context?: Context
): string =>
  `${getOriginForNewRegion(
    regionCode,
    domain
  )}${getRelativeUrlWithContextParamWithoutLocationSearch(
    croLandingPagePathname,
    context
  )}`;
