export interface AlternateDomainsSpeedTestConfig {
  testDelayInMilliseconds: number;
  fasterSpeedValueRatio: number;
  responseSizeToDownloadInBytes: number;
}

export const getDefaultAlternateDomainsSpeedTestConfig =
  (): AlternateDomainsSpeedTestConfig => ({
    testDelayInMilliseconds: 10_000,
    responseSizeToDownloadInBytes: 1_000_000,
    fasterSpeedValueRatio: 1.2,
  });
