import {
  logDebugInfo,
  logError,
  logSpeedVerificationResult,
} from 'frontend-container/components/AlternateDomainSwitching/logging/logging';
import { DomainInfo } from 'frontend-container/components/AlternateDomainSwitching/types/domainInfo';
import { AlternateDomainsSpeedTestConfig } from 'frontend-container/components/AlternateDomainSwitching/verification/configuration/testConfig';
import { getCurrentRegionDomains } from 'frontend-container/components/AlternateDomainSwitching/verification/region/getCurrentRegionDomains';
import { verifyDomainsDownloadSpeed } from 'frontend-container/components/AlternateDomainSwitching/verification/speedTest/verifyDomainsDownloadSpeed';

interface Result {
  fasterDomainThanCurrent?: DomainInfo;
}

interface Params {
  config: AlternateDomainsSpeedTestConfig;
}

export const proceedAlternateDomainsSpeedTest = async ({
  config,
}: Params): Promise<Result> => {
  try {
    logDebugInfo('Start alternate domains verification');

    const regionDomains = getCurrentRegionDomains();

    if (!regionDomains?.alternateDomains?.length) {
      logDebugInfo(
        'Stop alternate domains verification - alternate domains have not been found for region'
      );

      return {};
    }

    const speedResult = await verifyDomainsDownloadSpeed({
      domains: regionDomains,
      config,
    });

    logSpeedVerificationResult(speedResult);

    return {
      fasterDomainThanCurrent: speedResult?.fasterDomainThanCurrent?.domain,
    };
  } catch (error) {
    logError(error);

    return {};
  }
};
