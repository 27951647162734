import {
  getCustomerContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';

import { FeatureToggleName } from '@ac/library-api';

export const isAlternateDomainSwitchingEnabled = (): boolean => {
  const featureToggles =
    getPropertyContextData()?.featureToggles ??
    getCustomerContextData()?.featureToggles;

  return (
    featureToggles?.find(
      (toggle) =>
        toggle.featureName === FeatureToggleName.AlternateDomainSpeedTest
    )?.enabled ?? false
  );
};
