import { CurrentRegionDomains } from 'frontend-container/components/AlternateDomainSwitching/types/currentRegionDomains';
import { findRegionData } from 'frontend-container/utils/region/findRegion';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const getCurrentRegionDomains = (): CurrentRegionDomains | undefined => {
  const regionCode = getCurrentRegionCode();
  const region = findRegionData(regionCode);

  if (!region) {
    return;
  }

  return {
    regionCode,
    currentDomain: {
      web: region.domain,
      api: acConfig.apiUrl,
    },
    alternateDomains: region.alternateDomains ?? [],
  };
};
