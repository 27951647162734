import { DomainInfo } from 'frontend-container/components/AlternateDomainSwitching/types/domainInfo';

import {
  ConfigBuilder,
  isApiError,
  isLibraryApiResponse,
} from '@ac/library-api';
import { SpeedTestApi } from '@ac/library-api/dist/api/v0/systemMaintenance';
import { getErrorMessage } from '@ac/library-utils/dist/utils';

interface DownloadSpeedTestConfig {
  bytesToReturn: number;
}

interface SucceededApiCall {
  type: 'success';
}

type SucceededSpeedResult = {
  type: 'success';
  speedInMbps: number;
  timeInSeconds: number;
};

interface FailedResult {
  type: 'failed';
  error: string;
}

type DownloadSpeedResult = SucceededSpeedResult | FailedResult;

const OneMegaBytesInBytes = 1_000_000;

export class DomainSpeedTest {
  constructor(
    public domain: DomainInfo,
    private config: DownloadSpeedTestConfig
  ) {}

  async runDownloadSpeedTest(): Promise<DownloadSpeedResult> {
    const startTime = Math.round(performance.now());
    const callResult = await this.executeApiCall();
    const endTime = Math.round(performance.now());

    if (callResult.type === 'failed') {
      return callResult;
    }

    const contentSizeInMb =
      (this.config.bytesToReturn / OneMegaBytesInBytes) * 8;
    const timeInSeconds = (endTime - startTime) / 1_000;
    const speedInMbps = contentSizeInMb / timeInSeconds;

    return {
      type: 'success',
      speedInMbps,
      timeInSeconds,
    };
  }

  private async executeApiCall(): Promise<SucceededApiCall | FailedResult> {
    try {
      await SpeedTestApi.postSpeedTest({
        data: {
          bytesToReturn: this.config.bytesToReturn,
          requestContentText: '',
        },
        customConfig: new ConfigBuilder()
          .setHost(this.domain.api)
          .setSkipCache(true)
          .getConfig(),
      });

      return {
        type: 'success',
      };
    } catch (requestError: unknown) {
      if (isLibraryApiResponse(requestError) && isApiError(requestError.data)) {
        return {
          type: 'failed',
          error:
            getErrorMessage(requestError.data.details?.[0]) ??
            JSON.stringify(requestError),
        };
      }

      return {
        type: 'failed',
        error: JSON.stringify(requestError),
      };
    }
  }
}
