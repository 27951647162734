import { getLastSpeedTestDateTimeFromLocalStorage } from 'frontend-container/components/AlternateDomainSwitching/storage/localStorageData';
import {
  getCurrentDate,
  getDateFromString,
} from 'frontend-container/components/AlternateDomainSwitching/verification/testRepetition/dates';

import { LoginService } from '@ac/library-utils/dist/services';

const oneDayInMilliseconds = 24 * 3_600_000; // 24 hours in milliseconds

export const isSpeedTestResultOutdated = (): boolean => {
  const lastSpeedTestDateTime = getLastSpeedTestDateTimeFromLocalStorage({
    userId: getCurrentUserId(),
  });

  if (!lastSpeedTestDateTime) {
    return true;
  }

  const lastTestDate = getDateFromString(lastSpeedTestDateTime);
  const now = getCurrentDate();
  const timeDifference = now.getTime() - lastTestDate.getTime();

  return timeDifference >= oneDayInMilliseconds;
};

const getCurrentUserId = (): string => LoginService.authData()?.userId ?? '';
