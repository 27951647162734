import { setSpeedTestDateInLocalStorage } from 'frontend-container/components/AlternateDomainSwitching/storage/localStorageData';
import {
  getCurrentDate,
  getStringFromDate,
} from 'frontend-container/components/AlternateDomainSwitching/verification/testRepetition/dates';

import { LoginService } from '@ac/library-utils/dist/services';

export const saveSpeedTestMarkerInStore = (): void => {
  const userId = getCurrentUserId();

  setSpeedTestDateInLocalStorage({
    lastSpeedTestDateTime: getStringFromDate(getCurrentDate()),
    userId,
  });
};

const getCurrentUserId = (): string => LoginService.authData()?.userId ?? '';
